/* body {
    display: flex;
    overflow-x: auto;
    white-space: nowrap;
    padding: 10px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

hr {
    margin: 32px 0;
}

nav {
    border-radius: 8px;
    padding: 32px;
    text-align: center;
}

button, input {
    border-radius: 8px;
    box-sizing: border-box;
    display: block;
    font-size: 16px;
    margin: 8px 0;
    padding: 8px;
    width: 100%;
}

input {
    border: 1px solid;
}

button {
    border: 1px solid;
    cursor: pointer;
}

th {
  text-align: center;
  border: 1px solid;
  position: sticky;
  position: -webkit-sticky;
  top: 0;
}

*/

.no-outline {
  outline: 0 solid transparent
}

.thick-border {
  border: 1px dotted !important
}

.grey-background {
  background-color: transparent;
  border-color: black;
  border-radius: 6;
}

.resume-settings-bar {
    margin: 0px;
    top: -35px;
    left: 95%;
    position: relative;
    z-index: 1200;
    color: black
};

::-webkit-calendar-picker-indicator {
  filter: invert(100%) sepia(0%) saturate(7449%) hue-rotate(41deg) brightness(92%) contrast(100%);
}

iframe#webpack-dev-server-client-overlay{display:none!important}
